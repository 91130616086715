/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your custom styles go here */
:root {
    /* fonts */
    --font-raleway: Raleway;
    --font-lato: Lato;
    --font-montserrat: Montserrat;
    --font-manrope: Manrope;
    --font-barlow: Barlow;
  
    /* font sizes */
    --font-size-sm: 14px;
    --font-size-5xl: 24px;
    --font-size-base: 16px;
    --font-size-xl: 20px;
    --font-size-17xl: 36px;
    --font-size-11xl: 30px;
    --font-size-9xl: 28px;
    --font-size-xs: 12px;
    --font-size-lg: 18px;
    --font-size-29xl: 48px;
  
    /* Colors */
    --white: #fff;
    --black-text: #141414;
    --color-gray-100: #121212;
    --neutral-color-100: #00171f;
    --black: #070d15;
    --color-gray-200: rgba(255, 255, 255, 0.24);
    --color-gray-300: rgba(18, 18, 18, 0.24);
    --color-gray-500: rgba(255, 255, 255, 0.32);
    --color-gray-600: rgba(0, 0, 0, 0.5);
    --color-gray-400: rgba(20, 20, 20, 0.12);
    --color-gray-700: rgba(255, 255, 255, 0.1);
    --color-royalblue: #446ccf;
    --color-darkslateblue-100: #1e46aa;
    --color-darkslateblue-200: #1c3f98;
    --color-darkslateblue-300: rgba(10, 37, 103, 0.1);
    --color-darkgray-100: #b3b3b3;
    --gray-300: #a5acb5;
    --color-dodgerblue-100: #1f7ae6;
    --color-dodgerblue-200: #1f7ae5;
    --color-dimgray-100: #696969;
    --color-dimgray-200: #555;
    --color-wheat: #edd0a4;
    --color-gainsboro: #dbdbdb;
  
    /* Gaps */
    --gap-xs: 12px;
    --gap-5xl: 24px;
    --gap-5xs: 8px;
    --gap-17xl: 36px;
    --gap-mini: 15px;
    --gap-3xl: 22px;
    --gap-base: 16px;
    --gap-29xl: 48px;
    --gap-9xs: 4px;
    --gap-19xl: 38px;
    --gap-4xs: 9px;
    --gap-47xl: 66px;
    --gap-sm: 14px;
    --gap-7xs: 6px;
  
    /* Paddings */
    --padding-9xs: 4px;
    --padding-5xl: 24px;
    --padding-56xl: 75px;
    --padding-base: 16px;
    --padding-29xl: 48px;
    --padding-65xl: 84px;
    --padding-101xl: 120px;
    --padding-3xl: 22px;
    --padding-2xs: 11px;
    --padding-5xs: 8px;
    --padding-13xl: 32px;
    --padding-lg: 18px;
    --padding-sm: 14px;
    --padding-xs: 12px;
  
    /* Border radiuses */
    --br-76xl: 95px;
    --br-9xs: 4px;
    --br-7xs: 6px;
    --br-base: 16px;
    --br-3xs: 10px;
    --br-xs: 12px;
    --br-5xs: 8px;
    --br-11xs: 2px;
  }
  