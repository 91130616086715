@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.input-field {
  width: 320px !important;
  font-size: 16px !important;
  border: none !important;
  outline: none !important;
  transition: border-color 0.3s !important;
}

.custom-textarea {
  width: 320px !important;
  border: none !important;
  padding: 0 0 0 14px;
  margin-top: 14px !important;
  outline: none !important;
  resize: none !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  border-radius: 5px !important;
}

.contact-button {
  background-color: #1E46AA !important;
  color: #ffffff !important;
}

.frame {
  height: 653px;
  /* width: 1200px; */
}

.frame .overlap-group {
  height: 653px;
  left: -1px;
  position: relative;
  width: 1201px;
}

.frame .mask-group {
  height: 580px;
  left: 0;
  position: absolute;
  top: 73px;
  /* width: 1201px; */
}

.frame .form {
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 20px 40px #2a444b21;
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  left: 546px;
  padding: 24px 75px;
  position: absolute;
  top: 0;
  width: 550px;
}

.frame .text-wrapper {
  color: #141414;
  font-family: "Lato-SemiBold", Helvetica;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 46.8px;
  margin-left: -27px;
  margin-right: -27px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .content {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 400px;
}

.frame .name-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #b3b3b3;
  border-radius: 6px;
  height: 65px;
  position: relative;
  width: 400px;
}

.frame .name {
  height: 29px;
  left: 14px;
  position: relative;
  top: 17px;
  width: 371px;
}

.frame .frame-wrapper {
  height: 24px;
  width: 93px;
}

.frame .div {
  align-items: center;
  display: inline-flex;
  gap: 12px;
  position: relative;
}

.frame .img {
  height: 24px;
  position: relative;
  width: 24px;
}

.frame .input {
  background: transparent;
  border: none !important;
  outline: none !important;
  color: #141414;
  font-family: "Raleway-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  opacity: 0.4;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper {
  height: 24px;
  width: 88px;
}

.frame .div-2 {
  align-items: flex-start;
  display: inline-flex;
  gap: 12px;
  position: relative;
}

.frame .email {
  background: transparent;
  border: none;
  color: #141414;
  font-family: "Raleway-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  opacity: 0.4;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .name-2 {
  height: 24px;
  width: 96px;
}

.frame .text-wrapper-2 {
  color: #141414;
  font-family: "Raleway-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  opacity: 0.4;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .name-3 {
  height: 24px;
  width: 202px;
}

.frame .notes {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #b3b3b3;
  border-radius: 6px;
  height: 136px;
  position: relative;
  width: 399px;
}

.frame .div-3 {
  align-items: flex-start;
  display: inline-flex;
  gap: 12px;
  left: 17px;
  position: relative;
  top: 17px;
}

.frame .edit {
  height: 23px;
  position: relative;
  width: 23px;
}

.frame .button {
  all: unset;
  align-items: center;
  background-color: #1e46aa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  padding: 16px 48px;
  position: relative;
}

.frame .ask-a-question {
  color: #ffffff;
  font-family: "Raleway-SemiBold", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.96px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.hero-main {
  position: relative;
  height: 100%;
  width: 100%;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 30%;
  height: 100%;
}

.card {
  background-color: white !important;
  width: 100% !important;
  border: none !important;
}

.hidden-img {
  display: none;
}

@media (min-width: 1025px) {
  .hidden-img {
    display: block;
    /* Show the image */
  }
}

@media (max-width: 768px) {
  .input-field {
    width: 250px !important;
  }

  .custom-textarea {
    width: 250px !important;
  }
}

@media (max-width: 320px) {
  .input-field {
    width: 100% !important;
  }

  .custom-textarea {
    width: 100% !important;
  }
}

@media (max-width: 320px) {
  .input-field {
    max-width: 100% !important;
  }

  .custom-textarea {
    width: 100% !important;
  }
}